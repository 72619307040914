.custom-button-container {
    width: 100%;
}

.custom-button {
    width: 100%;
    height: 100%;
    background-color: #045FB4;
    border: none;
    border-radius: 8px;
    color: white;
    transition: background-color 0.5s ease;
}

.custom-button:hover {
    background-color: #8181F7;
}