.createModal {
  position: fixed;
  bottom: -150px;  /* 초기 위치를 화면 아래로 설정*/
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: bottom 0.3s ease;
  width: 300px;
  border-radius: 8px;
  z-index: 900;
  height: 150px;
  /* position: absolute; */
  /* left: 0; */
  /* border: solid 5px blue; */
  /* top: 0; */
}

.createModal.open {
  /* position: absolute; */
  bottom: 0px; /*모달이 올라오는 위치*/
  height: 150px;
  z-index: 900;
}

.modal-content {
  padding: 20px;
}

.close {
  cursor: pointer;
  font-size: 20px;
  float: right;
}