.grid-layout {
    display: grid;
    grid-template-columns: repeat(auto-fit, 10, 1fr);
    grid-auto-rows: repeat(auto-fit, 10, 1fr);
    grid-auto-flow: dense;
    padding: 0;
    gap: 10px;
}

.grid-item img {
    position: relative;
    overflow: hidden;
    width: 100%;
    object-fit: contain;
}

/* span classes */
.span-1 {
    grid-column-end: span 1;
    grid-row-end: span 1;
}

.span-2 {
    grid-column-end: span 2;
    grid-row-end: span 2;
}

.span-3 {
    grid-column-end: span 3;
    grid-row-end: span 3;
}

.span-4 {
    grid-column-end: span 4;
    grid-row-end: span 4;
}

.span-5 {
    grid-column-end: span 5;
    grid-row-end: span 5;
}

.span-6 {
    grid-column-end: span 6;
    grid-row-end: span 6;
}

.span-7 {
    grid-column-end: span 7;
    grid-row-end: span 7;
}

.span-8 {
    grid-column-end: span 8;
    grid-row-end: span 8;
}

.span-9 {
    grid-column-end: span 9;
    grid-row-end: span 9;
}

.span-10 {
    grid-column-end: span 10;
    grid-row-end: span 10;
}

/* tail classes */
.tail-1 {
    grid-column: 1; 
    grid-row: 1;    
    grid-row-end: span 1; 
}

.tail-2 {
    grid-column: 2; 
    grid-row: 1;    
    grid-row-end: span 2; 
}

.tail-3 {
    grid-column: 2; 
    grid-row: 3;    
    grid-row-end: span 4; 
}

.tail-4 {
    grid-column: 3; 
    grid-row: 1;    
    grid-row-end: span 2; 
}

.tail-5 {
    grid-column: 3; 
    grid-row: 3;    
    grid-row-end: span 3; 
}

.tail-6 {
    grid-column: 4; 
    grid-row: 1;    
    grid-row-end: span 2; 
}

.tail-7 {
    grid-column: 4; 
    grid-row: 3;    
    grid-row-end: span 3; 
}

.tail-8 {
    grid-column: 5; 
    grid-row: 1;    
    grid-row-end: span 2; 
}

.tail-9 {
    grid-column: 5; 
    grid-row: 3;    
    grid-row-end: span 3; 
}

.tail-10 {
    grid-column: 6; 
    grid-row: 1;    
    grid-row-end: span 2; 
}