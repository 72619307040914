.custom-image-modal {
    position: fixed;
    top: 50%; 
    left: 50%;
    width: 60vw;
    height: 60vh;
    transform: translate(-50%, -50%);
    z-index: 999;
    transition: display 0.3s ease-in-out;
}

.custom-image-modal.open {
    display: block;
}

.custom-image-modal.close {
    position: fixed;
    top: 50%; 
    left: 50%;
    width: 60vw;
    height: 60vh;
    transform: translate(-50%, -50%);
    z-index: 999;
    display: none;
}

.custom-image-container {
    width: 100%;
    height: 100%;
    max-height: 60vh;
    background-color: white;
}

.modal-overlay {
    transition: display 0.3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
}

.modal-overlay.close {
    display: none;
}

.modal-overlay.open {
    display: block;
}