/* .slide-in {
    animation: slide-in 1s ease-out forwards;
}

@keyframes slide-in {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
} */
.slide-in {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 1s ease-out, transform 1s;
  }
  
  .slide-in.show {
    opacity: 1;
    transform: translateX(0);
  }