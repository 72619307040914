.custom-input-container {
    position: relative;
    margin-bottom: 20px; /* 아래 여백을 추가 */
}

.custom-input {
    width: 100%;
    border: none;
    border-bottom: solid 2px gray;
    background-color: white;
    outline: none;
    transition: border-bottom 0.3s ease-in-out;
}

.custom-input:focus {
    border-bottom: solid 2px #8181F7;
}

.placeholder {
    position: absolute;
    top: 0; /* input의 위쪽 여백에 맞춰 조정 */
    left: 0;
    color: gray;
    transition: 0.2s; /* 애니메이션 효과 */
    pointer-events: none; /* label 클릭 방지 */
    background-color: transparent;
    padding-left: 15px;
}


/* input이 포커스될 때 label 위치 변화 */
.custom-input:focus + .placeholder,
.custom-input:not(:placeholder-shown) + .placeholder {
    top: -10px; /* label을 위로 이동 */
    font-size: 14px; /* 폰트 크기 조정 */
    color: gray; /* 색상 변경 */
}