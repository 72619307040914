@font-face {
  font-family: "myCustomFont";
  src: url("./font/onglipe_radio_pencel.ttf") format("truetype");
}

body {
  font-family: "myCustomFont";
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 20px;
  font-style: normal;
}

::-webkit-scrollbar { /* 스크롤바 */
  width: 10px;
}

::-webkit-scrollbar-track { /* 스크롤바 트랙 배경 */
  background-color: #FFFFFF;
}

::-webkit-scrollbar-thumb { /* 스크롤바 자체 */
  background-color: #E6E6E6;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #D8D8D8;
}