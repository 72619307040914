.modalConatiner {
    position: relative;
}

.inviteGroupModal {
    background-color: white;
    position: absolute;
    top: -600px;
    height: 30vh;
    width: 30vw;
    padding: 15px;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: top 0.3s ease-in-out;
}

.inviteGroupModal.open {
    background-color: white;
    position: absolute;
    top: 25vh;
    left: 50%;
    transform: translate(-50%, -50%); /* 중앙 정렬 및 위로 이동 */
}