.storageToast {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px red;   
    position: fixed;
    transform: translateX(-50%);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: bottom 0.3s ease;
    width: 300px;
    left: 50%;
    border-radius: 8px;
    z-index: 1000;
    height: 40px;
    bottom: -50px;
}

.storageToast.active {
    transition: bottom 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10px;
    z-index: 1000;
}

.storageToast.success {
    background-color: rgb(120, 206, 240);
}

.storageToast.failed {
    background-color: rgb(240, 120, 120);
}