.custom-image {
    object-fit: contain;
    background-color: #F2F2F2;
    width: 100%; 
    height: 15vh;
    max-height: 20vh; 
    border-radius: 8px;
    cursor: pointer;
    z-index: 1000;
    transition: box-shadow 0.2s ease-in-out;
}

.custom-image:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}